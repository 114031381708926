import { Component, OnInit } from '@angular/core';
import { CommService } from "../../comm.service";

@Component({
  selector: 'app-nastaveni',
  templateUrl: './nastaveni.component.html',
  styleUrls: ['../../app.component.css']
})
export class NastaveniComponent implements OnInit {
hodnota:number=1;
constructor(private comm: CommService) { }

  ngOnInit() {
  }
}
