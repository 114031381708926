import { Component, OnInit } from '@angular/core'
import BaseListComponent from 'src/app/class/BaseListComponent'
import { NgForm } from '@angular/forms'
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-planned-maintenace',
  templateUrl: './list.component.html',
  styleUrls: ['../../app.component.css']
})
export default class ListComponent extends BaseListComponent implements OnInit {
  data: any
  activeItem: any
  datum = {from: null, to: null}
  nextServiceDate = null

  ngOnInit() {
    const prevHalfyear = new Date(new Date().setDate(new Date().getDate()-180))
    const nextHalfyear = new Date(new Date().setDate(new Date().getDate()+180))
    this.datum = {
      from: new DatePipe('en-US').transform(prevHalfyear, 'yyyy-MM-dd'),
      // from: new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd'),
      to: new DatePipe('en-US').transform(nextHalfyear, 'yyyy-MM-dd')
    }
    this.nextServiceDate = new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd')
    super.ngOnInit()
  }

  getCommEntity() {
    return this.comm.plannedMaintenance()
  }

  loadData() {
    this.entityComm.index(this.datum).subscribe(
      (response) => {
        const responseData = response.json()
        if (!responseData.error) {
          this.data = responseData
        }
      },
      (error) => {
        const message = (error.json() || {}).message ? error.json().message : 'Chyba při načítání dat'
        this.notifier.notify('error', message)
        console.error({error})
      }
    );
  }

  changeColor(){
    var i;
    for (i = 0; i < this.data.length; i++) {
      if (Date.parse(this.data[i].datePlan) > Date.now()){this.data[i].status="good green"}
      else if(Date.parse(this.data[i].datePlan) == Date.now()){this.data[i].status="warning yellow"}
      else if(Date.parse(this.data[i].datePlan) < Date.now()){this.data[i].status="danger red"}
    }
  }

  getData() {
    this.changeColor()
    return super.getData()
  }

  onSubmit(form:NgForm){
    if (form.value.from!='') {this.datum.from = form.value.from}
    if (form.value.to!='') {this.datum.to = form.value.to}
    this.refresh()
  }

  onEditSubmit(form:NgForm){
    const nextDate = form.value.nextServiceDate
    const {id, udrzba_id} = this.activeItem
    this.entityComm.put({id, udrzba_id, nextDate}).subscribe(
      (response: any) => {
        const responseData = response.json()
        if (responseData && responseData.error) {
          console.error({responseData})
          this.notifier.notify('error', 'Nastala chyba při ukládání: ' + responseData.message)
        } else {
          this.closeEditDialog()
          this.notifier.notify('success', 'Změna úspěšně uložena')
        }
        this.refresh()
      },
      (error) => {
        const message = (error.json() || {}).message ? error.json().message : 'Chyba při ukládání dat'
        this.notifier.notify('error', message)
        console.error({error})
      }
    )
  }

  openEditDialog() {
    this.nextServiceDate = new DatePipe('en-US').transform(Date.parse(this.activeItem.datePlan), 'yyyy-MM-dd')
    this.ngxSmartModalService.getModal('tpmModal').open()
  }

  closeEditDialog() {
    this.ngxSmartModalService.getModal('tpmModal').close()
  }

  getHeading() {
    return 'Plánované odstávky'
  }
}
