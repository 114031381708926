import { Component, OnInit } from '@angular/core'
import BaseListComponent from 'src/app/class/BaseListComponent'

@Component({
  selector: 'app-komponenty',
  templateUrl: './list.component.html',
  styleUrls: ['../app.component.css']
})
export default class InventoryComponent extends BaseListComponent implements OnInit {
  data: any
  deviceTypes = []

  getCommEntity() {
    return this.comm.device()
  }

  loadData() {
    super.loadData()
    this.loadTypyZarizeni()
  }

  getFilters() {
    return this.deviceTypes.map(type => (
      {label: type.name, func: (item: any) => item.typZarizeni_id === type.id, value: type.id}
    ))
  }

  getFilteredType() {
    const activeFilter = this.getActiveFilter()
    return activeFilter ? activeFilter.value : 1 // default device type
  }

  loadTypyZarizeni() {
    this.comm.typyZarizeni().index().subscribe(
      (response) => {
        this.deviceTypes=response.json();
      },
      (error) => console.log(error)
    );
  }

  getHeading() {
    return 'Zařízení'
  }
}
