import { Component, OnInit } from '@angular/core'
import BaseListComponent from 'src/app/class/BaseListComponent'
import { NgForm } from '@angular/forms'
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-maintenance',
  templateUrl: './list.component.html',
  styleUrls: ['../../app.component.css']
})
export default class ListComponent extends BaseListComponent implements OnInit {
  data: any
  activeItem: any
  datum = {from: null, to: null}

  getHeading() {
    return 'Proběhlá údržba'
  }

  getCommEntity() {
    return this.comm.maintenance()
  }

  ngOnInit() {
    const prevMon = new Date(new Date().setDate(new Date().getDate()-30))
    this.datum = {
      from: new DatePipe('en-US').transform(prevMon, 'yyyy-MM-dd'),
      to: new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd')
    }
    super.ngOnInit()
  }

  loadData() {
    this.entityComm.index(this.datum).subscribe(
      (response) => {
        const responseData = response.json()
        if (!responseData.error) {
          this.data = responseData
        }
      },
      (error) => {
        const message = (error.json() || {}).message ? error.json().message : 'Chyba při načítání dat'
        this.notifier.notify('error', message)
        console.error({error})
      }
    );
  }

  onSubmit(form:NgForm){
    if (form.value.from!='') {this.datum.from = form.value.from}
    if (form.value.to!='') {this.datum.to = form.value.to}
    this.refresh()
  }
}
