import { Component, OnInit } from '@angular/core'
import BaseListComponent from 'src/app/class/BaseListComponent'
import { DatePipe } from '@angular/common'
import { NgForm } from '@angular/forms'

@Component({
  selector: 'app-komponenty',
  templateUrl: './list.component.html',
  styleUrls: ['../../app.component.css']
})
export default class ManufacturingHistoryComponent extends BaseListComponent implements OnInit {
  data: any
  activeItem: any
  zavady=[]
  datum = {from: null, to: null}

  ngOnInit() {
    const lastmonth = new Date(new Date().setDate(new Date().getDate()-30))
    this.datum = {
      from: new DatePipe('en-US').transform(lastmonth, 'yyyy-MM-dd'),
      to: new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd')
    }
    super.ngOnInit()
  }

  getCommEntity() {
    return this.comm.manufacturing()
  }

  loadData() {
    this.entityComm.index(this.datum).subscribe(
      (response) => {
        const responseData = response.json()
        if (!responseData.error) {
          this.data = responseData;
          this.changeColor()
        }
      },
      (error) => {
        const message = (error.json() || {}).message ? error.json().message : 'Chyba při načítání dat'
        this.notifier.notify('error', message)
        console.error({error})
      }
    );
  }

  changeColor(){
    var i;
    for (i = 0; i < this.data.length; i++) {
      if (this.data[i].Oee > 50 && this.data[i].Oee < 75){this.data[i].status="warning yellow"}
      else if(this.data[i].Oee > 75){this.data[i].status="good green"}
      else if(this.data[i].Oee < 50){this.data[i].status="danger red"}
    }
  }

  loadItemData(item) {
    this.comm.getAnalyzaZavadPnSmenaSloucene({idSmena: item.smenaId, od: '', do: ''}).subscribe(
     (response) => {
       this.zavady=response.json();
       console.log(this.zavady)
     },
     (error) => console.log(error))
  }

  getHeading() {
    return 'Proběhlá výroba'
  }

  onSubmit(form:NgForm){
    if (form.value.from!='') {this.datum.from = form.value.from}
    if (form.value.to!='') {this.datum.to = form.value.to}
    this.refresh()
  }

  onItemClick(item){
    this.activeItem = item;
    console.log(item)
    this.comm.getPocetZavad(item).subscribe(
      (response) => {
        const responseData = response.json()
        if (!responseData.error) {
          this.zavady = responseData;
          console.log(this.zavady)
          this.changeColor()
        }
      },
      (error) => {
        const message = (error.json() || {}).message ? error.json().message : 'Chyba při načítání dat'
        this.notifier.notify('error', message)
        console.error({error})
      }
    );
  }

}
