import { Component, OnInit } from '@angular/core'
import BaseListComponent from 'src/app/class/BaseListComponent'

@Component({
  selector: 'app-settings-list',
  templateUrl: './list.component.html',
  styleUrls: ['../../../app.component.css']
})
export default class SettingsList extends BaseListComponent implements OnInit {

  getCommEntity() {
    return this.comm.typyReklamaceStatus()
  }

  getHeading() {
    return 'Statusy reklamace'
  }

  getRoute() {
    return 'status-reklamace'
  }

}
