import { Component, OnInit, Input} from '@angular/core';
import { CommService } from "../../comm.service";
import {NgForm} from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-analyzazmetkovosti',
  templateUrl: './analyzaZmetkovosti.component.html',
  styleUrls: ['./../../app.component.css']
})
export class AnalyzaZmetkovostiComponent implements OnInit {
  constructor(private comm: CommService, private router:Router) { }
  nadpis1="Analýza zmetkovitosti";
  myWindow;
  analyzaid={"id":null, "od":"", "do":""};
  zavady=[];
  data=[{"pn":"", "total":null, "totalNOK":null, "image":"", "status":"", "Oee":null, "Kvalita":null, "Vykon":null}];
  vybranopole=[];
  index=[];
  babicka={"pn":"", "total":"", "totalNOK":""};
  zavadyView=[{"name":"", "mnozstvi":"",}];
  today=new Date();
  lastmonth=new Date(new Date().setDate(new Date().getDate()-30));
  dayafter=new DatePipe('en-US').transform(this.today, 'yyyy-MM-dd 23:59:59.993');
  dayback=new DatePipe('en-US').transform(this.lastmonth, 'yyyy-MM-dd');
  datum={"od":"", "do":"", "doSQL":""};
  typyVyrobku = []
  public activeFilter:number = -1

  ngOnInit() {
    console.log(this.dayafter);
    console.log(this.dayback);
    this.datum.od=this.dayback;
    this.datum.do=this.dayafter;
    this.datum.doSQL=this.dayafter;
    console.log(this.datum.doSQL);
    this.getanalyzazavad();
    this.comm.typyVyrobku().index().subscribe((res) => {this.typyVyrobku=res.json()},(err) => console.log(err));
  }

  getData() {
    const data = this.data
    if (this.activeFilter >= 0) {
      const filterFunc = this.getFilters()[this.activeFilter].func
      return data.filter(filterFunc)
    } else {
      return data
    }
  }

  getFilters() {
    return this.typyVyrobku.map(type => (
      {label: type.name, func: (item: any) => item.typVyrobku === type.id, value: type.id}
    ))
  }

  setFilter(index: number) {
    if (this.activeFilter === index) {
      this.activeFilter = -1
    } else {
      this.activeFilter = index
    }
  }

  getActiveFilter() {
    return this.activeFilter === -1 ? null : this.getFilters()[this.activeFilter]
  }

  onSubmit(form:NgForm){
    if (form.value.od!=''){this.datum.od=form.value.od};
    if (form.value.do!=''){this.datum.do=form.value.do};
    console.log(this.datum.od);
    this.datum.doSQL=this.datum.do+" 23:59:59.993";
    console.log(this.datum.doSQL);
    this.comm.getAnalyzaZavadFiltr(this.datum).subscribe(
      //(response) => console.log(response),
      (response) => {
        this.data=response.json();
        console.log(this.data);
      },
      (error) => console.log(error));
      return this.data;
  }

  myFunctionSearch(tablename) {
    var input, filter, table, tr, td, i;
    input = document.getElementById("myInput"+tablename);
    filter = input.value.toUpperCase();
    table = document.getElementById("myTable1");
    tr = table.getElementsByTagName("tr");
    for (i = 1; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[tablename];
      if (td) {
        if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  getanalyzazavad(){
    this.comm.getAnalyzaZavadFiltr(this.datum).subscribe(
      (response) => {this.data=response.json(); console.log(this.data); this.changeColor()},
      (error) => console.log(error));
      return this.data;
  }

  changeColor(){
    var i;
    for (i = 0; i < this.data.length; i++) {
      if (this.data[i].Oee > 50 && this.data[i].Oee < 75){this.data[i].status="warning yellow"}
      else if(this.data[i].Oee > 75){this.data[i].status="good green"}
      else if(this.data[i].Oee < 50){this.data[i].status="danger red"}
    }
  }

  clickNaPolozku(polozka){
   this.analyzaid.id=polozka.pn;
   this.analyzaid.od=this.datum.od;
   this.analyzaid.do=this.datum.doSQL;
   console.log(this.analyzaid);
   this.comm.getAnalyzaZavadPn(this.analyzaid).subscribe(
    (response) => {
      this.zavady=response.json();
      console.log(this.zavady);
    },
    (error) => console.log(error));
  }

  getanalyzazavadpn(){
    this.comm.getAnalyzaZavadPn(this.analyzaid).subscribe(
      (response) => {
        this.zavady=response.json();
        this.comm.zavadyView=this.zavady;
      },
      (error) => console.log(error));
  }



  download(){
    var csvData = this.ConvertToCSV(this.data);
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url= window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'Data.csv';
    a.click();
}

  ConvertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {
        //Now convert each value to string and comma-separated
        row += index + ';';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ';'

            line += array[i][index];
        }
        str += line + '\r\n';
    }
    return str;
}


}
