import { Component, OnInit, OnDestroy } from '@angular/core'
import { NgForm } from '@angular/forms'
import { permissionTypes } from 'src/config'
import BaseEditationComponent from 'src/app/class/BaseEditationComponent'

@Component({
  selector: 'app-uzivateleEdit',
  templateUrl: './uzivateleEdit.component.html',
  styleUrls: ['../../app.component.css']
})
export class UzivateleEditComponent extends BaseEditationComponent implements OnInit, OnDestroy {
  data = {
    id: null, jmeno:'', cislo:'', email:'', pozice: null, newPassword: '', newPassword2: '', isAdmin: 0, permissions: []
  }
  requiredFields = ['jmeno', 'cislo', 'pozice']
  uzivatelskePozice = []
  permissionTypes: any;

  ngOnInit() {
    super.ngOnInit()
    this.route.queryParams
    .filter(params => params.position)
    .subscribe(params => {
      if (+params.position) {
        this.data.pozice = +params.position;
      }
    })
  }

  getAuthUser() {
      return this.comm.getAuthUser()
  }

  isDisabled() {
      return !this.comm.can('write', 'Uživatelé')
  }

  getCommEntity() {
    return this.comm.users();
  }

  loadData() {
    this.comm.uzivatelskePozice().index().subscribe((res) => {this.uzivatelskePozice=res.json()},(err) => console.log(err));
    this.permissionTypes = permissionTypes;
    this.sub = this.route.params.subscribe(params => {
       this.id = +params['id']
       if (this.id) {

         // guard that only admins can edit users (OR any user can edit themself)
         const user = this.comm.getAuthUser()
         if (user.id && !user.isAdmin && this.id !== +user.id) {
           this.notifier.notify('error', 'Nemáte právo na editaci jiného uživatele')
           this.router.navigate(['/'])
         }

         this.entityComm.get(this.id).subscribe(
           (response) => {
             const responseData = response.json()
             if (responseData.error) {
               this.notifier.notify('error', responseData.message)
               this.router.navigate(['/'])
             } else {
               this.data = {...responseData, newPassword: '', newPassword2: ''}
             }
           },
           (error) => {
             const message = (error.json() || {}).message ? error.json().message : 'Chyba při ukládání dat'
             this.notifier.notify('error', message)
             console.error({error})
           }
         )
       }
    })
  }

  getHeading() {
    return this.id ? this.data.cislo ? `Editace uživatele ${this.data.cislo}` : 'Načítám uživatele...' : 'Nový uživatel'
  }

  setPermission(type, param, value) {
    const index = this.data.permissions.findIndex(p => p.permission === type)
    if (index < 0 && value) {
      // permission not granted yet
      this.data.permissions.push({"permission": type, "read": 0, 'write': 0, [param]: value})
    } else if (index >= 0) {
      this.data.permissions[index][param] = value
      // remove dead permissions (read and write both 0)
      this.data.permissions = this.data.permissions.filter(p => p.read || p.write)
    }
  }

  hasPermission(type, param) {
    if (this.data.isAdmin) {
      return true
    }
    const perm = (this.data.permissions || []).find(p => p.permission === type)
    return perm ? perm[param] : false
  }

  afterSave() {
    this.router.navigate(['/system/uzivatele']);
  }

  validate(data) {
    if (this.isDisabled()) {
      return []
    }
    return super.validate(data)
  }
}
