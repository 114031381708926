import { Component, OnInit, OnDestroy } from '@angular/core'
import { NgForm } from '@angular/forms'
import BaseEditationComponent from 'src/app/class/BaseEditationComponent'
import 'rxjs/add/operator/filter'

@Component({
  selector: 'app-recalamtion-editace',
  templateUrl: './detail.component.html',
  styleUrls: ['../../app.component.css']
})
export default class EditaceReklamaceComponent extends BaseEditationComponent implements OnInit, OnDestroy {
  data = {
    num: '', sestavaId: null, image: '', typReklamace: 1, date_receive: null, date_8D: null,
    typReklamaceStatus: 1, provozovna: null, note: '', note_1: '', note_2: ''
  }
  requiredFields = ['num', 'sestavaId', 'date_receive', 'date_8D']
  products = []
  provozovny = []
  typyReklamace = []
  typyReklamaceStatus = []
  objectKeys = Object.keys;
  fmFieldMappings = {
    imageField: 'image',
  }

  getCommEntity() {
    return this.comm.reclamation();
  }

  // typVyrobku cannot be prefilled - it is dependant on sestavaId
  // ngOnInit() {
  //   super.ngOnInit()
  //   this.route.queryParams
  //   .filter(params => params.type)
  //   .subscribe(params => {
  //     this.data.typReklamace = params.type;
  //   })
  // }

  loadData() {
    super.loadData()
    this.comm.typyReklamace().index().subscribe((res) => {this.typyReklamace=res.json()},(err) => console.log(err));
    this.comm.typyReklamaceStatus().index().subscribe((res) => {this.typyReklamaceStatus=res.json()},(err) => console.log(err));
    this.comm.products().index().subscribe((res) => {this.products=res.json()},(err) => console.log(err));
    this.comm.provozovny().index().subscribe((res) => {this.provozovny=res.json()},(err) => console.log(err));
  }

  getHeading() {
    return this.id ? this.data.num ? `Editace reklamace ${this.data.num}` : 'Načítám reklamaci...' : 'Nová reklamace'
  }

  afterSave() {
    this.router.navigate(['/kvalita/reklamace']);
  }

  parseFormToData(form: NgForm) {
    if (form.value.num!='') {this.data.num = form.value.num}
    if (form.value.date_8D!='') {this.data.date_8D = form.value.date_8D}
    if (form.value.date_receive!='') {this.data.date_receive = form.value.date_receive}
    if (form.value.note!='') {this.data.note = form.value.note}
    if (form.value.note_1!='') {this.data.note_1 = form.value.note_1}
    if (form.value.note_2!='') {this.data.note_2 = form.value.note_2}
  }
}
