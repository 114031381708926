import { Component, OnInit } from '@angular/core'
import BaseListComponent from 'src/app/class/BaseListComponent'

@Component({
  selector: 'app-settings-list',
  templateUrl: '../generic-list.component.html',
  styleUrls: ['../../../app.component.css']
})
export default class SettingsList extends BaseListComponent implements OnInit {

  getCommEntity() {
    return this.comm.uzivatelskePozice()
  }

  getHeading() {
    return 'Uživatelské pozice'
  }

  getRoute() {
    return 'uzivatelske-pozice'
  }

}
