import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdfviewer',
  templateUrl: './pdfviewer.component.html',
  styleUrls: ['./../app.component.css']
})
export class PDFViewerComponent implements OnInit {

  constructor() { }
nadpis1="Pracovní pokyny PN 1-2345678-9";
  ngOnInit() {
  }

}
