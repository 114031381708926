import { Component, OnInit, OnDestroy } from '@angular/core'
import { NgForm } from '@angular/forms'
import BaseEditationComponent from 'src/app/class/BaseEditationComponent'

@Component({
  selector: 'app-settings',
  templateUrl: './detail.component.html',
  styleUrls: ['./../../../app.component.css']
})
export default class DetailForm extends BaseEditationComponent implements OnInit, OnDestroy {
  data = {
    id: null, number: '', question: '', metoda: '', oblast: '', typ: ''
  }
  requiredFields = ['number', 'question']
  metody=[];
  oblasti=[];
  typy=[];
  objectKeys = Object.keys;
  fmFieldMappings = {
    // imageField: 'image',
  }

  getCommEntity() {
    return this.comm.otazkyAuditu();
  }

  getHeading() {
    return this.id ? this.data.number ? `Editace otázky ${this.data.number}` : 'Načítám otázky...' : 'Nová otázka'
  }

  afterSave() {
    this.router.navigate(['/system/nastaveni/otazky-auditu']);
  }

  parseFormToData(form: NgForm) {
    if (form.value.number!=''){this.data.number = form.value.number};
    if (form.value.question!=''){this.data.question = form.value.question};
  }

  loadData() {
    super.loadData()
    this.loadMetody()
    this.loadOblasti()
    this.loadTypy()
  }

  loadMetody(){
    this.comm.metodyAuditu().index().subscribe(
      (response) => { console.log('metody', response.json());this.metody = response.json() },
      (error) => console.error({error})
    )
  }

  loadOblasti(){
    this.comm.oblastAuditu().index().subscribe(
      (response) => { console.log('loadOblasti', response.json());this.oblasti = response.json() },
      (error) => console.error({error})
    )
  }

  loadTypy(){
    this.comm.typyAuditu().index().subscribe(
      (response) => { console.log('loadTypy', response.json());this.typy = response.json() },
      (error) => console.error({error})
    )
  }

  setTypAuditu(event: any) {
    this.data.typ = event.target.value
  }

  setMetoda(event: any) {
    this.data.metoda = event.target.value
  }

  setOblast(event: any) {
    this.data.oblast = event.target.value
  }
}
