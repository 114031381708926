import { Component, OnInit } from '@angular/core';
import { CommService } from "../comm.service";

@Component({
  selector: 'app-nastenka',
  templateUrl: './nastenka.component.html',
  styleUrls: ['../app.component.css']
})
export class NastenkaComponent implements OnInit {

constructor(private comm: CommService) { }

data_summ={"summ_reklamace":"", "summ_vyroba":"", "summ_udrzby":""};

  ngOnInit() {
    this.getSummUdrzba();
    this.getSummVyroba();
    this.getSummReklamace();
  }

  getSummUdrzba(){
    this.comm.getSummUdrzba().subscribe(
      (response) => {
        this.comm.data_summ=response.json();
        this.data_summ.summ_udrzby=this.comm.data_summ[0].summ_udrzby;
      },
      (error) => console.log(error));
      return this.data_summ;
  }
  
  getSummVyroba(){
    this.comm.getSummVyroba().subscribe(
      (response) => {
        this.comm.data_summ=response.json();
        this.data_summ.summ_vyroba=this.comm.data_summ[0].summ_vyroba;
      },
      (error) => console.log(error));
      return this.data_summ;
  }
  
  getSummReklamace(){
  this.comm.getSummReklamace().subscribe(
    (response) => {
      this.comm.data_summ=response.json();
      this.data_summ.summ_reklamace=this.comm.data_summ[0].summ_reklamace;
    },
    (error) => console.log(error));
    return this.data_summ;
  }

}
