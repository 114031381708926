// escapes a string for use in regular expressions
export const escapeRegExp = s => s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

/**
* Sets a cookie.
*/
export function setCookie(name, value, expirationMinutes) {
  let d = new Date()
  d.setTime(d.getTime() + (expirationMinutes * 60 * 1000))
  let expires = 'expires=' + d.toUTCString()
  document.cookie = name + '=' + value + '; ' + expires + '; path=/'
}

/**
* Deletes a cookie.
*/
export function deleteCookie(name) {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/'
}

/**
* Returns a single cookie by name.
*/
export function getCookie(name) {
  name += '='
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
