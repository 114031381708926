import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {CommService} from '../comm.service';
import { NotifierService } from 'angular-notifier'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../app.component.css']
})
export class LoginComponent implements OnInit {
  data={"email":"", "password":"", remember: 0};
  requiredFields = ['email', 'password'];
  online=""; offline=""; database;
  private notifier: NotifierService;

  constructor(private router:Router, private comm: CommService, notifier: NotifierService) {
    this.notifier = notifier
  }

  ngOnInit() {
    this.getSummVyroba();
    // move to root if revalidation will happen
    // this.router.navigate(['/'])
  }

  getSummVyroba(){
    this.comm.getDatabase().subscribe(
      (response) => {
        this.database=response.json();
        this.online="ONLINE";
      },
      (error) => {
        this.offline="OFFLINE";
      });
      return this.database;
  }

  setRemember(value) {
    this.data.remember = value
  }

  validate(data) {
    const errors = this.requiredFields.reduce((errs, field) => {
      return !data[field] ? errs.concat(['Pole ' + field.toUpperCase() + ' je povinné!']) : errs
    }, [])
    return errors
  }

  onSubmit(form:NgForm){
    const stdFormFields = ['email', 'password']
    stdFormFields.map(field => {
      if (form.value[field] != '') {
        this.data[field] = form.value[field]
      }
    })

    // validate
    const errors = this.validate(this.data)
    if (errors.length) {
      errors.map(err => this.notifier.notify('error', err))
      return
    }

    this.comm.login(this.data).subscribe(
      (response: any) => {
        const responseData = JSON.parse(response._body || '{}')
        if (responseData && responseData.error) {
          console.error({responseData})
          this.notifier.notify('error', responseData.message)
        } else {
          const {token, user} = responseData
          this.comm.saveUser(user)
          this.comm.saveToken(token, this.data.remember)
          this.router.navigate(['/'])
        }
      },
      (error) => {
        this.notifier.notify('error', 'Chyba při přihlašování')
        console.error({error})
      }
    )
  }

}
