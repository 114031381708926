/**
 * this file consists of some universal settings and definitions that are used across the application
 */

import { environment } from './environments/environment'

const apiConfigs = {
  production: {
    url: 'https://api.appkampos.cz',
    fmUrl: 'https://cdn.appkampos.cz/fm/',
    fmAccessKey: 'temporaryStaticAKey_xbn6g5n41d56fgn1'
  },
  dev: {
    url: 'https://api.appkampos.cz',
    fmUrl: 'https://cdn.appkampos.cz/fm/',
    fmAccessKey: 'temporaryStaticAKey_xbn6g5n41d56fgn1'
  },
  local: {
    url: 'http://localhost:4201',
    fmUrl: 'http://194.182.68.12/fm/',
    fmAccessKey: 'temporaryStaticAKey_xbn6g5n41d56fgn1'
  }
}

export const apiConfig = apiConfigs[environment.env]

// types of assignable permissions to users
export const permissionTypes = [
  'Systém',
  'Uživatelé',
  'Nástěnka',
  'Výroba',
  'Produkty',
  'Inventář',
  'Údržba',
  'Kvalita',
  'Audit',
  'TabletZávady',
  'TabletAudit',
  'TabletÚdržba',
  // future
  'TabletDokumentace',
  'TabletKontrola',
  'TabletBlokVyroba'
]
