import { Injectable, OnInit } from '@angular/core'
import { CommService } from 'src/app/comm.service'
import { Router, ActivatedRoute } from '@angular/router'
import { NotifierService } from 'angular-notifier'
import { NgxSmartModalService } from 'ngx-smart-modal'

@Injectable()
class BaseListComponent implements OnInit {
  protected entityComm: any
  public data: object[] = []
  public checkedIndexes: number[] = []
  public activeItem: object = {}
  public objectKeys = Object.keys
  public activeFilter:number = -1

  constructor(
    protected comm: CommService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected ngxSmartModalService: NgxSmartModalService,
    protected notifier: NotifierService
  ) {
    this.entityComm = this.getCommEntity()
  }

  // override!
  getCommEntity() {
    return null;
  }

  ngOnInit() {
    this.loadData()
  }

  getHeading() {
    return 'Výpis'
  }

  getFilters() {
    return [
      // {label: 'Konektory', func: (item: any) => item.typ === 1}
    ]
  }

  setFilter(index: number) {
    if (this.activeFilter === index) {
      this.activeFilter = -1
    } else {
      this.activeFilter = index
    }
  }

  getActiveFilter() {
    return this.activeFilter === -1 ? null : this.getFilters()[this.activeFilter]
  }

  loadData() {
    this.entityComm.index().subscribe(
      (response) => {
        const responseData = response.json()
        if (!responseData.error) {
          this.data = responseData
        }
      },
      (error) => {
        const message = (error.json() || {}).message ? error.json().message : 'Chyba při načítání dat'
        this.notifier.notify('error', message)
        console.error({error})
      }
    );
  }

  refresh() {
    this.data = []
    this.loadData()
  }

  getData() {
    const data = this.data
    if (this.activeFilter >= 0) {
      const filterFunc = this.getFilters()[this.activeFilter].func
      return data.filter(filterFunc)
    } else {
      return data
    }
  }

  onItemClick(item){
    this.activeItem = item
    this.loadItemData(item)
  }

  loadItemData(item: any) {
    // for possible overriding
  }

  onItemDelete(id) {
    if (confirm("Opravdu smazat tuto položku?")) {
      this.entityComm.delete(id).subscribe(
        (response: any) => {
          const responseData = response.json()
          if ((responseData && responseData.error) || !responseData.result) {
            console.error({responseData})
            this.notifier.notify('error', 'Nastala chyba při mazání: ' + responseData.message)
          } else {
            this.data = this.data.filter((item: any) => item.id !== id) // remove from view
            this.activeItem = {}
            this.notifier.notify('success', 'Položka odstraněna')
          }
        },
        (error) => {
          const message = (error.json() || {}).message ? error.json().message : 'Chyba při mazání dat'
          this.notifier.notify('error', message)
          console.error({error})
        }
      )
    }
  }

  onItemCheck(index: number, isChecked:boolean){
    this.checkedIndexes = [];
    if(isChecked) {
      this.checkedIndexes.push(index);
    } else {
      this.checkedIndexes.splice(index, 1);
    }
  }

  myFunctionSearch(tablename) {
    var input, filter, table, tr, td, i;
    input = document.getElementById("myInput"+tablename);
    filter = input.value.toUpperCase();
    table = document.getElementById("myTable1");
    tr = table.getElementsByTagName("tr");
    for (i = 1; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[tablename];
      if (td) {
        if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  sort() {
    this.data.sort((a: any, b: any) => (a.id)-(b.id));
  }

  sortrev() {
    this.data.sort((a: any, b: any) => (b.id)-(a.id));
  }

  download() {
    var csvData = this.ConvertToCSV(this.data);
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url= window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'Data.csv';
    a.click();
  }

  ConvertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {
      //Now convert each value to string and comma-separated
      row += index + ';';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ';'

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }
}

export default BaseListComponent
