import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { GoogleChartsModule } from 'angular-google-charts';
import { AmChartsModule } from "@amcharts/amcharts3-angular";
import { NotifierModule } from 'angular-notifier';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { AppComponent } from './app.component';

import { CommService } from './comm.service';

import { LoginComponent } from './login/login.component';
import { PDFViewerComponent } from './pdfviewer/pdfviewer.component';

//SYSTEMOVE
//         1. UZIVATELE
import { UzivateleComponent } from './system/uzivatele/uzivatele.component';
import { UzivateleEditComponent } from "./system/uzivatele/uzivateleEdit.component";

//         2. NASTAVENI
import { NastaveniComponent } from './system/nastaveni/nastaveni.component';

import NastaveniManufacturersComponent from './system/nastaveni/manufacturers/list.component';
import EditaceManufacturersComponent from './system/nastaveni/manufacturers/detail.component';

import NastaveniOtazkyAudituComponent from './system/nastaveni/otazkyAuditu/list.component';
import EditaceOtazkyAudituComponent from './system/nastaveni/otazkyAuditu/detail.component';

import NastaveniProvozovnaComponent from './system/nastaveni/provozovna/list.component';
import EditaceProvozovnaComponent from './system/nastaveni/provozovna/detail.component';

import NastaveniRozdeleniVyrobkuComponent from './system/nastaveni/rozdeleniVyrobku/list.component';
import EditaceRozdeleniVyrobkuComponent from './system/nastaveni/rozdeleniVyrobku/detail.component';

import NastaveniRozdeleniVyrobyComponent from './system/nastaveni/rozdeleniVyroby/list.component';
import EditaceRozdeleniVyrobyComponent from './system/nastaveni/rozdeleniVyroby/detail.component';

import NastaveniTypyAudituComponent from './system/nastaveni/typyAuditu/list.component';
import EditaceTypyAudituComponent from './system/nastaveni/typyAuditu/detail.component';

import NastaveniTypyUdrzbyComponent from './system/nastaveni/typyUdrzby/list.component';
import EditaceTypyUdrzbyComponent from './system/nastaveni/typyUdrzby/detail.component';

import NastaveniTypyZarizeniComponent from './system/nastaveni/typyZarizeni/list.component';
import EditaceTypyZarizeniComponent from './system/nastaveni/typyZarizeni/detail.component';

import NastaveniTypyZavadComponent from './system/nastaveni/typyZavad/list.component';
import EditaceTypyZavadComponent from './system/nastaveni/typyZavad/detail.component';

import NastaveniUzivatelskePoziceComponent from './system/nastaveni/uzivatelskePozice/list.component';
import EditaceUzivatelskePoziceComponent from './system/nastaveni/uzivatelskePozice/detail.component';

import NastaveniTypyReklamaceComponent from './system/nastaveni/typyReklamace/list.component';
import EditaceTypyReklamaceComponent from './system/nastaveni/typyReklamace/detail.component';

import NastaveniTypyReklamaceStatusComponent from './system/nastaveni/typyReklamaceStatus/list.component';
import EditaceTypyReklamaceStatusComponent from './system/nastaveni/typyReklamaceStatus/detail.component';

import NastaveniMetodyAudituComponent from './system/nastaveni/metodyAuditu/list.component';
import EditaceMetodyAudituComponent from './system/nastaveni/metodyAuditu/detail.component';

import NastaveniOblastAudituComponent from './system/nastaveni/oblastAuditu/list.component';
import EditaceOblastAudituComponent from './system/nastaveni/oblastAuditu/detail.component';

//NASTĚNKA
import { NastenkaComponent } from "./nastenka/nastenka.component";

//VÝROBA
//         1. ROZPRACOVANA
import ManufacturingComponent from "./vyroba/aktualniVyroba/list.component";
import { EditaceVyrobaComponent } from "./vyroba/aktualniVyroba/editace/editace.component";

//         2. DOKONCENA
import HistorieVyrobaComponent from "./vyroba/historieVyroba/list.component";

//PRODUKTY
//         1. KOMPONENTY
import { KomponentyComponent } from './komponenty/komponenty.component';
import { EditaceKomponentyComponent } from './komponenty/editace/editace.component';

//         2. SESTAVY PN
import { ProduktyComponent } from './produkty/produkty.component';
import { EditaceProduktyComponent } from './produkty/editace/editace.component';

// INVENTÁŘ
import ZarizeniComponent from "./zarizeni/list.component";
import EditaceZarizeniComponent from "./zarizeni/detail.component";

//UDRZBA
//         1. ROZPRACOVNANA
import RozpracUdrzbaComponent from "./udrzba/rozpracovanaUdrzba/list.component";
//         2. DOKONCENA
import ProbehlaUdrzbaComponent from "./udrzba/probehlaUdrzba/list.component";
//         3. PLÁNOVANÉ ODSTÁVKY
import TPMComponent from "./udrzba/TPM/list.component";
//         4. EDITACE
// import { EditaceUdrzbaComponent } from "./udrzba/editace/editace.component";

//KVALITA
//         1. REKLAMACE
import ReklamaceComponent from "./kvalita/reklamace/list.component";
import EditaceReklamaceComponent from "./kvalita/reklamace/detail.component";

//         2. ANALÝZA ZMETKOVITOSTI
import { AnalyzaZmetkovostiComponent } from './kvalita/analyzaZmetkovosti/analyzaZmetkovosti.component';
import { AnalyzaZmetkovostiNahledComponent } from "./kvalita/analyzaZmetkovosti/analyzaZmetkovostiNahled/analyzaZmetkovostiNahled.component";

//         3. KONTROLNI KARTA A
import KartaAComponent from "./kvalita/kartaA/list.component";

//         4. KONTROLNI KARTA B
import KartaBComponent from "./kvalita/kartaB/list.component";

//AUDITY
import { AnalyzaLPAComponent } from "./audit/analyzaLPA/analyzaLPA.component";
import { AuditLpaNahledComponent } from "./audit/analyzaLPA/auditLpaNahled/auditLpaNahled.component";

const appRoutes:Routes=[
  { path: '', redirectTo: '/nastenka', pathMatch: 'full' },
  {path:'login', component:LoginComponent},
  {path:'pdfviewer', component:PDFViewerComponent},

//SYSTÉMOVÉ
//         1. UŽIVATELE
  {path:'system/uzivatele', component:UzivateleComponent},
  {path:'system/uzivatele/editace/:id', component:UzivateleEditComponent},
  {path:'system/uzivatele/editace', component:UzivateleEditComponent},
//         2. NASTAVENÍ
  {path:'system/nastaveni', component:NastaveniComponent},

  {path:'system/nastaveni/otazky-auditu', component:NastaveniOtazkyAudituComponent},
  {path:'system/nastaveni/otazky-auditu/editace/:id', component:EditaceOtazkyAudituComponent},
  {path:'system/nastaveni/otazky-auditu/editace', component:EditaceOtazkyAudituComponent},


  {path:'system/nastaveni/provozovna', component:NastaveniProvozovnaComponent},
  {path:'system/nastaveni/provozovna/editace/:id', component:EditaceProvozovnaComponent},
  {path:'system/nastaveni/provozovna/editace', component:EditaceProvozovnaComponent},

  {path:'system/nastaveni/rozdeleni-vyrobku', component:NastaveniRozdeleniVyrobkuComponent},
  {path:'system/nastaveni/rozdeleni-vyrobku/editace/:id', component:EditaceRozdeleniVyrobkuComponent},
  {path:'system/nastaveni/rozdeleni-vyrobku/editace', component:EditaceRozdeleniVyrobkuComponent},

  {path:'system/nastaveni/rozdeleni-vyroby', component:NastaveniRozdeleniVyrobyComponent},
  {path:'system/nastaveni/rozdeleni-vyroby/editace/:id', component:EditaceRozdeleniVyrobyComponent},
  {path:'system/nastaveni/rozdeleni-vyroby/editace', component:EditaceRozdeleniVyrobyComponent},

  {path:'system/nastaveni/typy-auditu', component:NastaveniTypyAudituComponent},
  {path:'system/nastaveni/typy-auditu/editace/:id', component:EditaceTypyAudituComponent},
  {path:'system/nastaveni/typy-auditu/editace', component:EditaceTypyAudituComponent},

  {path:'system/nastaveni/typy-udrzby', component:NastaveniTypyUdrzbyComponent},
  {path:'system/nastaveni/typy-udrzby/editace/:id', component:EditaceTypyUdrzbyComponent},
  {path:'system/nastaveni/typy-udrzby/editace', component:EditaceTypyUdrzbyComponent},

  {path:'system/nastaveni/typy-zarizeni', component:NastaveniTypyZarizeniComponent},
  {path:'system/nastaveni/typy-zarizeni/editace/:id', component:EditaceTypyZarizeniComponent},
  {path:'system/nastaveni/typy-zarizeni/editace', component:EditaceTypyZarizeniComponent},

  {path:'system/nastaveni/typy-zavad', component:NastaveniTypyZavadComponent},
  {path:'system/nastaveni/typy-zavad/editace/:id', component:EditaceTypyZavadComponent},
  {path:'system/nastaveni/typy-zavad/editace', component:EditaceTypyZavadComponent},

  {path:'system/nastaveni/uzivatelske-pozice', component:NastaveniUzivatelskePoziceComponent},
  {path:'system/nastaveni/uzivatelske-pozice/editace/:id', component:EditaceUzivatelskePoziceComponent},
  {path:'system/nastaveni/uzivatelske-pozice/editace', component:EditaceUzivatelskePoziceComponent},

  {path:'system/nastaveni/typy-reklamace', component:NastaveniTypyReklamaceComponent},
  {path:'system/nastaveni/typy-reklamace/editace/:id', component:EditaceTypyReklamaceComponent},
  {path:'system/nastaveni/typy-reklamace/editace', component:EditaceTypyReklamaceComponent},

  {path:'system/nastaveni/status-reklamace', component:NastaveniTypyReklamaceStatusComponent},
  {path:'system/nastaveni/status-reklamace/editace/:id', component:EditaceTypyReklamaceStatusComponent},
  {path:'system/nastaveni/status-reklamace/editace', component:EditaceTypyReklamaceStatusComponent},

  {path:'system/nastaveni/manufacturers', component:NastaveniManufacturersComponent},
  {path:'system/nastaveni/manufacturers/editace/:id', component:EditaceManufacturersComponent},
  {path:'system/nastaveni/manufacturers/editace', component:EditaceManufacturersComponent},

  {path:'system/nastaveni/metody-auditu', component:NastaveniMetodyAudituComponent},
  {path:'system/nastaveni/metody-auditu/editace/:id', component:EditaceMetodyAudituComponent},
  {path:'system/nastaveni/metody-auditu/editace', component:EditaceMetodyAudituComponent},

  {path:'system/nastaveni/oblasti-auditu', component:NastaveniOblastAudituComponent},
  {path:'system/nastaveni/oblasti-auditu/editace/:id', component:EditaceOblastAudituComponent},
  {path:'system/nastaveni/oblasti-auditu/editace', component:EditaceOblastAudituComponent},

//NASTĚNKA
  {path:'nastenka', component:NastenkaComponent},

//VÝROBA
//         1. ROZPRACOVANA
  {path:'vyroba/rozpracovana-vyroba', component:ManufacturingComponent},
  {path:'vyroba/rozpracovana-vyroba/editace/:id', component:EditaceVyrobaComponent},
//         2. PROBEHLA
  {path:'vyroba/probehla-vyroba', component:HistorieVyrobaComponent},

//PRODUKTY
//         1. KOMPONENTY
  {path:'produkty/komponenty', component:KomponentyComponent},
  {path:'produkty/komponenty/editace', component:EditaceKomponentyComponent},
  {path:'produkty/komponenty/editace/:id', component:EditaceKomponentyComponent},

//         2. SESTAVY PN
  {path:'produkty/sestavy', component:ProduktyComponent},
  {path:'produkty/sestavy/editace', component:EditaceProduktyComponent},
  {path:'produkty/sestavy/editace/:id', component:EditaceProduktyComponent},

// INVENTÁŘ
  {path:'inventar/zarizeni', component:ZarizeniComponent},
  {path:'inventar/zarizeni/editace', component:EditaceZarizeniComponent},
  {path:'inventar/zarizeni/editace/:id', component:EditaceZarizeniComponent},

//ÚDRŽBA
  {path:'udrzba/probehla-udrzba', component:ProbehlaUdrzbaComponent},
  {path:'udrzba/rozpracovana-udrzba', component:RozpracUdrzbaComponent},
  {path:'udrzba/planovane-odstavky', component:TPMComponent},
  // {path:'udrzba/editace', component:EditaceUdrzbaComponent},

//KVALITA
//          1.REKLAMACE
  {path:'kvalita/reklamace', component:ReklamaceComponent},
  {path:'kvalita/reklamace/editace', component:EditaceReklamaceComponent},
  {path:'kvalita/reklamace/editace/:id', component:EditaceReklamaceComponent},
//          2. ANALÝZA ZMETKOVITOSTI
  {path:'kvalita/analyza-zmetkovitost', component:AnalyzaZmetkovostiComponent},
  {path:'kvalita/analyza-zmetkovitost/nahled', component:AnalyzaZmetkovostiNahledComponent},
//         3. KONTROLNI KARTA A
  {path:'kvalita/karta-a', component:KartaAComponent},
//         4. KONTROLNI KARTA B
  {path:'kvalita/karta-b', component:KartaBComponent},

//AUDITY
  {path:'audit/analyza-lpa', component:AnalyzaLPAComponent},
  {path:'audit/analyza-lpa/nahled', component:AuditLpaNahledComponent},
]

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PDFViewerComponent,
//SYSTÉMOVÉ
    UzivateleComponent,
    UzivateleEditComponent,
    NastaveniComponent,
    NastaveniOtazkyAudituComponent,
    EditaceOtazkyAudituComponent,
    NastaveniProvozovnaComponent,
    EditaceProvozovnaComponent,
    NastaveniRozdeleniVyrobkuComponent,
    EditaceRozdeleniVyrobkuComponent,
    NastaveniRozdeleniVyrobyComponent,
    EditaceRozdeleniVyrobyComponent,
    NastaveniTypyAudituComponent,
    EditaceTypyAudituComponent,
    NastaveniTypyUdrzbyComponent,
    EditaceTypyUdrzbyComponent,
    NastaveniTypyZarizeniComponent,
    EditaceTypyZarizeniComponent,
    NastaveniTypyZavadComponent,
    EditaceTypyZavadComponent,
    NastaveniUzivatelskePoziceComponent,
    EditaceUzivatelskePoziceComponent,
    NastaveniTypyReklamaceComponent,
    EditaceTypyReklamaceComponent,
    NastaveniTypyReklamaceStatusComponent,
    EditaceTypyReklamaceStatusComponent,
    NastaveniManufacturersComponent,
    EditaceManufacturersComponent,
    NastaveniMetodyAudituComponent,
    EditaceMetodyAudituComponent,
    NastaveniOblastAudituComponent,
    EditaceOblastAudituComponent,
//NÁSTĚNKA
    NastenkaComponent,
//VÝROBA
    ManufacturingComponent,
    EditaceVyrobaComponent,
    HistorieVyrobaComponent,
//PRODUKTY
    KomponentyComponent,
    EditaceKomponentyComponent,
    ProduktyComponent,
    EditaceProduktyComponent,
//INVENTÁŘ
    ZarizeniComponent,
    EditaceZarizeniComponent,
//UDRZBA
    ProbehlaUdrzbaComponent,
    RozpracUdrzbaComponent,
    TPMComponent,
    // EditaceUdrzbaComponent,
//KVALITA
    ReklamaceComponent,
    EditaceReklamaceComponent,
    AnalyzaZmetkovostiComponent,
    AnalyzaZmetkovostiNahledComponent,
    KartaAComponent,
    KartaBComponent,
//AUDITY
    AnalyzaLPAComponent,
    AuditLpaNahledComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    GoogleChartsModule,
    AmChartsModule,
    RouterModule.forRoot(appRoutes),
    NotifierModule.withConfig({
      position: {horizontal: {position: 'right'}},
      behaviour: {autoHide: 4000}
    }),
    NgxSmartModalModule.forRoot()
  ],
  providers: [CommService],
  bootstrap: [AppComponent]
})
export class AppModule { }
