import { Component, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { CommService } from "./comm.service"
import { NgxSmartModalService } from 'ngx-smart-modal'
import { apiConfig } from 'src/config'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  constructor(
    private comm: CommService,
    protected ngxSmartModalService: NgxSmartModalService,
    protected sanitizer: DomSanitizer
  ) { }

  ngOnInit(){
    if (this.isLoggedIn()) {
    }
  }

  isLoggedIn() {
    return this.comm.isLoggedIn()
  }

  getAuthUser() {
    return this.comm.getAuthUser()
  }

  canDisplay(what = '') {
    return this.comm.can('read', what)
  }

  logout() {
    return this.comm.logout()
  }

  dejdata(){
    this.comm.products().index().subscribe(
      (response) => console.log(response),
      (error) => console.log(error)
    );
  }

  getFMDialogUrl(type = 1) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      apiConfig.fmUrl + 'dialog.php?type=' + type + '&lang=cs&popup=0&crossdomain=1&relative_url=0&akey=temporaryStaticAKey_xbn6g5n41d56fgn1'
    );
  }
}
