import { Component, OnInit, Input, NgZone } from '@angular/core';
import { CommService } from "../../../comm.service";
import {NgForm} from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
//import { AmChartsService, AmChart } from '@amcharts/amcharts3-angular';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-auditLpaNahled',
  templateUrl: './auditLpaNahled.component.html',
  styleUrls: ['./../../../app.component.css']
})

export class AuditLpaNahledComponent implements OnInit {
  private chart: am4charts.XYChart;
  constructor(private comm: CommService, private router:Router, private zone: NgZone) { }

nadpis1="Hodnocení LPA auditů";
options: any;
data=[];
mezikrok=[{"ANO":"", "NE":"", "Celk":"", "Cast":"", "mesic":null}];
mezikrok7=[{"ANO":"", "NE":"", "Celk":"", "Cast":"", "mesic":null}];
mezikrok7S=[{"ANO":"", "NE":"", "Celk":"", "Cast":"", "mesic":null}];
mezikrok14=[{"ANO":"", "NE":"", "Celk":"", "Cast":"", "mesic":null}];
mezikrok30=[{"ANO":"", "NE":"", "Celk":"", "Cast":"", "mesic":null}];

fdata1 = {
  [0]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [1]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [2]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [3]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [4]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [5]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [6]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [7]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [8]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [9]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [10]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [11]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
};

fdata7 = {
  [0]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [1]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [2]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [3]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [4]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [5]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [6]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [7]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [8]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [9]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [10]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [11]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
};

fdata7S = {
  [0]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [1]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [2]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [3]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [4]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [5]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [6]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [7]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [8]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [9]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [10]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [11]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
};

fdata14 = {
  [0]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [1]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [2]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [3]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [4]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [5]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [6]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [7]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [8]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [9]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [10]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [11]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
};

fdata30 = {
  [0]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [1]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [2]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [3]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [4]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [5]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [6]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [7]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [8]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [9]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [10]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
  [11]: {"ANO":null, "NE":null, "Celk":null, "Cast":null, "mesic":null, "Spp":null, "status":""},
};

data1=[];
data7=[];
data7S=[];
data14=[];
data30=[];
chartData = {
  [0]: {"mesic": "Leden", "uroven0":null, "uroven1V":null, "uroven2":null, "uroven3":null},
  [1]: {"mesic": "Únor", "uroven0":null, "uroven1V":null, "uroven2":null, "uroven3":null},
  [2]: {"mesic": "Březen", "uroven0":null, "uroven1V":null, "uroven2":null, "uroven3":null},
  [3]: {"mesic": "Duben", "uroven0":null, "uroven1V":null, "uroven2":null, "uroven3":null},
  [4]: {"mesic": "Květen", "uroven0":null, "uroven1V":null, "uroven2":null, "uroven3":null},
  [5]: {"mesic": "Červen", "uroven0":null, "uroven1V":null, "uroven2":null, "uroven3":null},
  [6]: {"mesic": "Červenec", "uroven0":null, "uroven1V":null, "uroven2":null, "uroven3":null},
  [7]: {"mesic": "Srpen", "uroven0":null, "uroven1V":null, "uroven2":null, "uroven3":null},
  [8]: {"mesic": "Září", "uroven0":null, "uroven1V":null, "uroven2":null, "uroven3":null},
  [9]: {"mesic": "Říjen", "uroven0":null, "uroven1V":null, "uroven2":null, "uroven3":null},
  [10]: {"mesic": "Listopad", "uroven0":null, "uroven1V":null, "uroven2":null, "uroven3":null},
  [11]: {"mesic": "Prosinec", "uroven0":null, "uroven1V":null, "uroven2":null, "uroven3":null},
};

today=new Date();
  lastmonth=new Date(new Date().setDate(new Date().getDate()-30));
  dayafter=new DatePipe('en-US').transform(this.today, 'yyyy-12-31 23:59:59.993');
  dayback=new DatePipe('en-US').transform(this.lastmonth, 'yyyy-01-01');

 getreport={"od":"", "typ":"", "doSQL":"", "do":""};

  ngOnInit() {
    console.log(this.dayafter);
    console.log(this.dayback);

    this.getreport.od=this.dayback;
    this.getreport.doSQL=this.dayafter;
    this.getauditlpareport1();
    this.getauditlpareport7();
    this.getauditlpareport7S();
    this.getauditlpareport14();
    this.getauditlpareport30();
    this.chartDatafunction();
  }

  getAuthUser() {
    return this.comm.getAuthUser()
}

  amChart() {
    this.zone.runOutsideAngular(() => {

      // Create chart instance
  let chart = am4core.create("chartdiv", am4charts.XYChart);

  // Add data
  var i;
  for (i = 0; i < 12; i++) {
    chart.data[i] = this.chartData[i];
    }
  console.log(chart.data);
  console.log("teď");

// Modify chart's colors
chart.colors.list = [
  am4core.color("#fff600"),
  am4core.color("#4cdf00"),
  am4core.color("#3aad00"),
  am4core.color("#0086db"),
  am4core.color("#d20a00"),
];

  // Create axes
var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
categoryAxis.dataFields.category = "mesic";
categoryAxis.title.text = "Hodnocené období";
categoryAxis.renderer.grid.template.location = 0;
categoryAxis.renderer.minGridDistance = 30;
categoryAxis.renderer.labels.template.horizontalCenter = "right";
categoryAxis.renderer.labels.template.verticalCenter = "middle";
categoryAxis.renderer.labels.template.rotation = 270;
categoryAxis.tooltip.disabled = true;

var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.title.text = "Stupeň plnění požadavků v %";

var series1 = chart.series.push(new am4charts.ColumnSeries());
series1.dataFields.valueY = "uroven0";
series1.dataFields.categoryX = "mesic";
series1.dummyData = {
  name: "Úroveň 0"
};
series1.adapter.add("name", function(name, target) {
  return target.dummyData.name;
});

var series2 = chart.series.push(new am4charts.ColumnSeries());
series2.dataFields.valueY = "uroven1V";
series2.dataFields.categoryX = "mesic";
series2.dummyData = {
  name: "Úroveň 1V"
};
series2.adapter.add("name", function(name, target) {
  return target.dummyData.name;
});

var series3 = chart.series.push(new am4charts.ColumnSeries());
series3.dataFields.valueY = "uroven1S";
series3.dataFields.categoryX = "mesic";
series3.dummyData = {
  name: "Úroveň 1S"
};
series3.adapter.add("name", function(name, target) {
  return target.dummyData.name;
});

var series4 = chart.series.push(new am4charts.ColumnSeries());
series4.dataFields.valueY = "uroven2";
series4.dataFields.categoryX = "mesic";
series4.dummyData = {
  name: "Úroveň 2"
};
series4.adapter.add("name", function(name, target) {
  return target.dummyData.name;
});

var series5 = chart.series.push(new am4charts.ColumnSeries());
series5.dataFields.valueY = "uroven3";
series5.dataFields.categoryX = "mesic";
series5.dummyData = {
  name: "Úroveň 3"
};
series5.adapter.add("name", function(name, target) {
  return target.dummyData.name;
});

// Create value axis range
var range = valueAxis.axisRanges.create();
range.value = 85;
range.grid.stroke = am4core.color("#919191");
range.grid.strokeWidth = 1;
range.grid.strokeOpacity = 1;
range.label.inside = true;
range.label.text = "A";
range.label.fill = range.grid.stroke;
//range.label.align = "right";
range.label.verticalCenter = "bottom";

var range2 = valueAxis.axisRanges.create();
range2.value = 60;
range2.grid.stroke = am4core.color("#919191");
range2.grid.strokeWidth = 1;
range2.grid.strokeOpacity = 1;
range2.label.inside = true;
range2.label.text = "B";
range2.label.fill = range2.grid.stroke;
//range2.label.align = "right";
range2.label.verticalCenter = "bottom";

chart.exporting.menu = new am4core.ExportMenu();
chart.legend = new am4charts.Legend();
this.chart = chart;

    });
  }

  chartDatafunction(){
    var i;
    for (i = 0; i < 12; i++) {
    this.chartData[i].uroven0=this.fdata1[i].Spp;
    this.chartData[i].uroven1V=this.fdata7[i].Spp;
    this.chartData[i].uroven1S=this.fdata7S[i].Spp;
    this.chartData[i].uroven2=this.fdata14[i].Spp;
    this.chartData[i].uroven3=this.fdata30[i].Spp;
    }
    this.amChart();
  }

  getauditlpareport1(){
    //1denní
    this.getreport.typ="1";
    this.comm.getAuditLpaReport(this.getreport).subscribe(
      (response) => {
        this.data1=response.json();
        this.getdatatransform1();
      },
      (error) => console.log(error));
      return this.data1;
  }

  getdatatransform1(){
    var i;
    var n=0;
   for (i = 1; i < 13; i++) {
    this.mezikrok = this.data1.filter( element => element.mesic ==i) //1
      if (this.mezikrok.length==0){
        this.fdata1[n].mesic=i;
        this.fdata1[n].ANO=0;
        this.fdata1[n].Cast=0;
        this.fdata1[n].NE=0;
        this.fdata1[n].Celk=0;
        this.fdata1[n].Spp=0;
        this.fdata1[n].status="text-white";
      }
      else {
        this.fdata1[n].mesic=this.mezikrok[0].mesic;
        this.fdata1[n].ANO=parseInt(this.mezikrok[0].ANO);
        this.fdata1[n].Cast=parseInt(this.mezikrok[0].Cast);
        this.fdata1[n].NE=parseInt(this.mezikrok[0].NE);
        this.fdata1[n].Celk=parseInt(this.mezikrok[0].Celk);
        this.fdata1[n].Spp=((this.fdata1[n].ANO * 2 + this.fdata1[n].Cast * 1)/(this.fdata1[n].Celk * 2))*100;
        if (this.fdata1[n].Spp >= 85){this.fdata1[n].status="text-success"}
        else if(this.fdata1[n].Spp >= 60){this.fdata1[n].status="text-warning"}
        else if(this.fdata1[n].Spp < 60){this.fdata1[n].status="text-danger"}
        else if(this.fdata1[n].Spp == 0){this.fdata1[n].status="text-white"}
      }
      n=n+1;
    }
    this.chartDatafunction();
  }

  getauditlpareport7(){
    //7denní
    this.getreport.typ="7";
    this.comm.getAuditLpaReport(this.getreport).subscribe(
      (response) => {
        this.data7=response.json();
        this.getdatatransform7();
      },
      (error) => console.log(error));
      return this.data7;
  }

  getdatatransform7(){
    var i;
    var n=0;
   for (i = 1; i < 13; i++) {
    this.mezikrok7 = this.data7.filter( element => element.mesic ==i) //1
      if (this.mezikrok7.length==0){
        this.fdata7[n].mesic=i;
        this.fdata7[n].ANO=0;
        this.fdata7[n].Cast=0;
        this.fdata7[n].NE=0;
        this.fdata7[n].Celk=0;
        this.fdata7[n].Spp=0;
        this.fdata7[n].status="text-white";
      }
      else {
        this.fdata7[n].mesic=this.mezikrok7[0].mesic;
        this.fdata7[n].ANO=parseInt(this.mezikrok7[0].ANO);
        this.fdata7[n].Cast=parseInt(this.mezikrok7[0].Cast);
        this.fdata7[n].NE=parseInt(this.mezikrok7[0].NE);
        this.fdata7[n].Celk=parseInt(this.mezikrok7[0].Celk);
        this.fdata7[n].Spp=((this.fdata7[n].ANO * 2 + this.fdata7[n].Cast * 1)/(this.fdata7[n].Celk * 2))*100;
        if (this.fdata7[n].Spp >= 85){this.fdata7[n].status="text-success"}
        else if(this.fdata7[n].Spp >= 60){this.fdata7[n].status="text-warning"}
        else if(this.fdata7[n].Spp < 60){this.fdata7[n].status="text-danger"}
        else if(this.fdata7[n].Spp == 0){this.fdata7[n].status="text-white"}
      }
      n=n+1;
    }
    this.chartDatafunction();
  }

  getauditlpareport7S(){
    //7denní
    this.getreport.typ="8";
    this.comm.getAuditLpaReport(this.getreport).subscribe(
      (response) => {
        this.data7S=response.json();
        this.getdatatransform7S();
      },
      (error) => console.log(error));
      return this.data7S;
  }

  getdatatransform7S(){
    var i;
    var n=0;
   for (i = 1; i < 13; i++) {
    this.mezikrok7S = this.data7S.filter( element => element.mesic ==i) //1
      if (this.mezikrok7S.length==0){
        this.fdata7S[n].mesic=i;
        this.fdata7S[n].ANO=0;
        this.fdata7S[n].Cast=0;
        this.fdata7S[n].NE=0;
        this.fdata7S[n].Celk=0;
        this.fdata7S[n].Spp=0;
        this.fdata7S[n].status="text-white";
      }
      else {
        this.fdata7S[n].mesic=this.mezikrok7S[0].mesic;
        this.fdata7S[n].ANO=parseInt(this.mezikrok7S[0].ANO);
        this.fdata7S[n].Cast=parseInt(this.mezikrok7S[0].Cast);
        this.fdata7S[n].NE=parseInt(this.mezikrok7S[0].NE);
        this.fdata7S[n].Celk=parseInt(this.mezikrok7S[0].Celk);
        this.fdata7S[n].Spp=((this.fdata7S[n].ANO * 2 + this.fdata7S[n].Cast * 1)/(this.fdata7S[n].Celk * 2))*100;
        if (this.fdata7S[n].Spp >= 85){this.fdata7S[n].status="text-success"}
        else if(this.fdata7S[n].Spp >= 60){this.fdata7S[n].status="text-warning"}
        else if(this.fdata7S[n].Spp < 60){this.fdata7S[n].status="text-danger"}
        else if(this.fdata7S[n].Spp == 0){this.fdata7S[n].status="text-white"}
      }
      n=n+1;
    }
    this.chartDatafunction();
  }


  getauditlpareport14(){
    //14denní
    this.getreport.typ="14";
    this.comm.getAuditLpaReport(this.getreport).subscribe(
      (response) => {
        this.data14=response.json();
        this.getdatatransform14();
      },
      (error) => console.log(error));
      return this.data14;
  }

  getdatatransform14(){
    var i;
    var n=0;
   for (i = 1; i < 13; i++) {
    this.mezikrok14 = this.data14.filter( element => element.mesic ==i) //1
      if (this.mezikrok14.length==0){
        this.fdata14[n].mesic=i;
        this.fdata14[n].ANO=0;
        this.fdata14[n].Cast=0;
        this.fdata14[n].NE=0;
        this.fdata14[n].Celk=0;
        this.fdata14[n].Spp=0;
        this.fdata14[n].status="text-white";
      }
      else {
        this.fdata14[n].mesic=this.mezikrok14[0].mesic;
        this.fdata14[n].ANO=parseInt(this.mezikrok14[0].ANO);
        this.fdata14[n].Cast=parseInt(this.mezikrok14[0].Cast);
        this.fdata14[n].NE=parseInt(this.mezikrok14[0].NE);
        this.fdata14[n].Celk=parseInt(this.mezikrok14[0].Celk);
        this.fdata14[n].Spp=((this.fdata14[n].ANO * 2 + this.fdata14[n].Cast * 1)/(this.fdata14[n].Celk * 2))*100;
        if (this.fdata14[n].Spp >= 85){this.fdata14[n].status="text-success"}
        else if(this.fdata14[n].Spp >= 60){this.fdata14[n].status="text-warning"}
        else if(this.fdata14[n].Spp < 60){this.fdata14[n].status="text-danger"}
        else if(this.fdata14[n].Spp == 0){this.fdata14[n].status="text-white"}
      }
      n=n+1;
    }
    this.chartDatafunction();
  }

  getauditlpareport30(){
    //30denní
    this.getreport.typ="30";
    this.comm.getAuditLpaReport(this.getreport).subscribe(
      (response) => {
        this.data30=response.json();
        this.getdatatransform30();
      },
      (error) => console.log(error));
      return this.data30;
  }

  getdatatransform30(){
    var i;
    var n=0;
   for (i = 1; i < 13; i++) {
    this.mezikrok30 = this.data30.filter( element => element.mesic ==i) //1
      if (this.mezikrok30.length==0){
        this.fdata30[n].mesic=i;
        this.fdata30[n].ANO=0;
        this.fdata30[n].Cast=0;
        this.fdata30[n].NE=0;
        this.fdata30[n].Celk=0;
        this.fdata30[n].Spp=0;
        this.fdata30[n].status="text-white";
      }
      else {
        this.fdata30[n].mesic=this.mezikrok30[0].mesic;
        this.fdata30[n].ANO=parseInt(this.mezikrok30[0].ANO);
        this.fdata30[n].Cast=parseInt(this.mezikrok30[0].Cast);
        this.fdata30[n].NE=parseInt(this.mezikrok30[0].NE);
        this.fdata30[n].Celk=parseInt(this.mezikrok30[0].Celk);
        this.fdata30[n].Spp=((this.fdata30[n].ANO * 2 + this.fdata30[n].Cast * 1)/(this.fdata30[n].Celk * 2))*100;
        if (this.fdata30[n].Spp >= 85){this.fdata30[n].status="text-success"}
        else if(this.fdata30[n].Spp >= 60){this.fdata30[n].status="text-warning"}
        else if(this.fdata30[n].Spp < 60){this.fdata30[n].status="text-danger"}
        else if(this.fdata30[n].Spp == 0){this.fdata30[n].status="text-white"}
      }
      n=n+1;
    }
    this.chartDatafunction();
  }

  onSubmit(form:NgForm){
    if (form.value.od!=''){this.getreport.od=form.value.od};
    if (form.value.do!=''){this.getreport.do=form.value.do};
    console.log(this.getreport.od);
    this.getreport.doSQL=this.getreport.do+" 23:59:59.993";
    console.log(this.getreport.doSQL);
    this.getauditlpareport1();
    this.getauditlpareport7();
    this.getauditlpareport7S();
    this.getauditlpareport14();
    this.getauditlpareport30();
    this.amChart();
  }

  dejdata(){
    this.comm.products().index().subscribe(
      (response) => console.log(response),
      (error) => console.log(error));
  }


}
