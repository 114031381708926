import { Component, OnInit, Input, NgZone } from '@angular/core';
import { CommService } from "../../../comm.service";
import {NgForm} from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
//import { AmChartsService, AmChart } from '@amcharts/amcharts3-angular';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-reklamaceNahled',
  templateUrl: './analyzaZmetkovostiNahled.component.html',
  styleUrls: ['./../../../app.component.css']
})

export class AnalyzaZmetkovostiNahledComponent implements OnInit {
  private chart: am4charts.XYChart;
  constructor(private comm: CommService, private router:Router, private zone: NgZone) { }

nadpis1="Analýza zmetkovitosti";
options: any;
data=[];
chartData=[{"pn": "", "total":null, "totalNOK":null}];
today=new Date();
  lastmonth=new Date(new Date().setDate(new Date().getDate()-30));
  dayafter=new DatePipe('en-US').transform(this.today, 'yyyy-MM-dd 23:59:59.993');
  dayback=new DatePipe('en-US').transform(this.lastmonth, 'yyyy-MM-dd');
  datum={"od":"", "do":"", "doSQL":""};

  ngOnInit() {
    console.log(this.dayafter);
    console.log(this.dayback);
    this.datum.od=this.dayback;
    this.datum.do=this.dayafter;
    this.datum.doSQL=this.dayafter;
    console.log(this.datum.doSQL);
    this.getanalyzazavad();
  }

  getAuthUser() {
    return this.comm.getAuthUser()
}

  amChart() {
    this.zone.runOutsideAngular(() => {

      // Create chart instance
let chart = am4core.create("chartdiv", am4charts.XYChart);

// Add data
chart.data = this.chartData;
console.log(chart.data);
console.log("teď");
// Create axes
let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
categoryAxis.title.text = "Produkty";
categoryAxis.dataFields.category = "pn";
categoryAxis.renderer.grid.template.location = 0;
categoryAxis.renderer.minGridDistance = 30;

let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.title.text = "Počet NOK";

// Create series
let series = chart.series.push(new am4charts.ColumnSeries());
series.dataFields.valueY = "totalNOK";
series.dataFields.categoryX = "pn";
series.name = "Počet NOK";
series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
series.columns.template.fillOpacity = .8;

let columnTemplate = series.columns.template;
columnTemplate.strokeWidth = 2;
columnTemplate.strokeOpacity = 1;

chart.exporting.menu = new am4core.ExportMenu();
this.chart = chart;
    });
  }

  getanalyzazavad(){
    this.comm.getAnalyzaZavadFiltr(this.datum).subscribe(
      //(response) => console.log(response),
      (response) => {
        this.data=response.json();
        console.log(this.data);
        this.chartData=this.data;
        console.log(this.chartData);
        console.log("teď");
        this.amChart();
      },
      (error) => console.log(error));
      return this.data;
  }

  onSubmit(form:NgForm){
    if (form.value.od!=''){this.datum.od=form.value.od};
    if (form.value.do!=''){this.datum.do=form.value.do};
    console.log(this.datum.od);
    this.datum.doSQL=this.datum.do+" 23:59:59.993";
    console.log(this.datum.doSQL);
    this.comm.getAnalyzaZavadFiltr(this.datum).subscribe(
      //(response) => console.log(response),
      (response) => {
        this.data=response.json();
        console.log(this.data);
        this.chartData=this.data;
        console.log(this.chartData);
        console.log("teď");
        this.amChart();
      },
      (error) => console.log(error));
      return this.data;
  }

  dejdata(){
    this.comm.products().index().subscribe(
      (response) => console.log(response),
      (error) => console.log(error));
  }

}
