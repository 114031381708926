import { Component, OnInit, OnDestroy } from '@angular/core'
import { NgForm } from '@angular/forms'
import BaseEditationComponent from 'src/app/class/BaseEditationComponent'
import 'rxjs/add/operator/filter'

@Component({
  selector: 'app-zarizeni-editace',
  templateUrl: './detail.component.html',
  styleUrls: ['../app.component.css']
})
export default class EditaceKomponentyComponent extends BaseEditationComponent implements OnInit, OnDestroy {
  data = {
    id: null, int_id: '', name: '', extern_id: '', docPlan: '', maintainPeriod: null, provozovna_id: null,
    typZarizeni_id: 1, image: '', notes_maintain: '', manufacturer: null, show_tablet: 0
  }
  requiredFields = ['int_id', 'name']
  deviceTypes = []
  provozovny = []
  manufacturers = []
  objectKeys = Object.keys;
  fmFieldMappings = {
    imageField: 'image',
    docPlanField: 'docPlan',
  }

  getCommEntity() {
    return this.comm.device();
  }

  ngOnInit() {
    super.ngOnInit()
    this.route.queryParams
    .filter(params => params.type)
    .subscribe(params => {
      this.data.typZarizeni_id = params.type;
    })
  }

  loadData() {
    super.loadData()
    this.loadTypyZarizeni()
    this.loadProvozovny()
    this.loadManufacturers()
  }

  getHeading() {
    return this.id ? this.data.int_id ? `Editace zařízení ${this.data.int_id}` : 'Načítám zařízení...' : 'Nové zařízení'
  }

  afterSave() {
    this.router.navigate(['/inventar/zarizeni']);
  }

  loadProvozovny(){
    this.comm.provozovny().index().subscribe(
      (response) => { this.provozovny = response.json() },
      (error) => console.error({error})
    )
  }

  loadManufacturers(){
    this.comm.manufacturers().index().subscribe(
      (response) => { this.manufacturers = response.json() },
      (error) => console.error({error})
    )
  }

  loadTypyZarizeni() {
    this.comm.typyZarizeni().index().subscribe(
      (response) => {
        this.deviceTypes=response.json();
      },
      (error) => console.log(error)
    );
  }

  setTypZarizeni(event: any) {
    this.data.typZarizeni_id = event.target.value
  }

  parseFormToData(form: NgForm) {
    if (form.value.int_id!='') {this.data.int_id = form.value.int_id}
    if (form.value.name!='') {this.data.name = form.value.name}
    if (form.value.extern_id!='') {this.data.extern_id = form.value.extern_id}
    if (form.value.notes_maintain!='') {this.data.notes_maintain = form.value.notes_maintain}
  }
}
