import { Component, OnInit, OnDestroy } from '@angular/core'
import { NgForm } from '@angular/forms'
import BaseEditationComponent from 'src/app/class/BaseEditationComponent'

@Component({
  selector: 'app-settings',
  templateUrl: './detail.component.html',
  styleUrls: ['./../../../app.component.css']
})
export default class DetailForm extends BaseEditationComponent implements OnInit, OnDestroy {
  data = {
    id: null, num: 0, name: '', image: ''
  }
  requiredFields = ['num', 'name']
  objectKeys = Object.keys;
  fmFieldMappings = {
    imageField: 'image'
  }

  getCommEntity() {
    return this.comm.typyZarizeni();
  }

  getHeading() {
    return this.id ? this.data.name ? `Editace typu zařízení ${this.data.name}` : 'Načítám typy...' : 'Nový typ zařízení'
  }

  afterSave() {
    this.router.navigate(['/system/nastaveni/typy-zarizeni']);
  }

  parseFormToData(form: NgForm) {
    if (form.value.num!=''){this.data.num = +form.value.num};
    if (form.value.name!=''){this.data.name = form.value.name};
  }
}
