import { Component, OnInit } from '@angular/core'
import BaseListComponent from 'src/app/class/BaseListComponent'

@Component({
  selector: 'app-produkty',
  templateUrl: './produkty.component.html',
  styleUrls: ['../app.component.css']
})
export class ProduktyComponent extends BaseListComponent implements OnInit {
  typyVyrobku = []

  getCommEntity() {
    return this.comm.products()
  }

  loadData() {
    super.loadData()
    this.comm.typyVyrobku().index().subscribe((res) => {this.typyVyrobku=res.json()},(err) => console.log(err));
  }

  getHeading() {
    return 'Sestavy PN'
  }

  getFilters() {
    return this.typyVyrobku.map(type => (
      {label: type.name, func: (item: any) => item.typVyrobku === type.id, value: type.id}
    ))
  }

  getFilteredType() {
    const activeFilter = this.getActiveFilter()
    return activeFilter ? activeFilter.value : 1 // default typVyrobku
  }

}
