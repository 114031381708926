import { Component, OnInit, OnDestroy } from '@angular/core'
import { NgForm } from '@angular/forms'
import BaseEditationComponent from 'src/app/class/BaseEditationComponent'
import 'rxjs/add/operator/filter'

@Component({
  selector: 'app-komponenty-editace',
  templateUrl: './editace.component.html',
  styleUrls: ['../../app.component.css']
})
export class EditaceKomponentyComponent extends BaseEditationComponent implements OnInit, OnDestroy {
  data = {
    id: null, "pn":"", "name":"", "typ": "1", "image": ""
  }
  requiredFields = ['pn']
  typvyroby=[];
  typyVyrobku=[];
  objectKeys = Object.keys;
  fmFieldMappings = {
    imageField: 'image',
  }

  getCommEntity() {
    return this.comm.components();
  }

  ngOnInit() {
    super.ngOnInit()
    this.route.queryParams
    .filter(params => params.type)
    .subscribe(params => {
      this.data.typ = params.type;
    })
  }

  loadData() {
    super.loadData()
    this.loadTypvyroby()
    this.loadTypVyrobku()
  }

  getHeading() {
    return this.id ? this.data.pn ? `Editace komponenty ${this.data.pn}` : 'Načítám komponent...' : 'Nový komponent'
  }

  afterSave() {
    this.router.navigate(['/produkty/komponenty']);
  }

  loadTypvyroby(){
    this.comm.typyVyroby().index().subscribe(
      (response) => { this.typvyroby = response.json() },
      (error) => console.error({error})
    )
  }

  loadTypVyrobku(){
    this.comm.typyVyrobku().index().subscribe(
      (response) => { this.typyVyrobku = response.json() },
      (error) => console.error({error})
    )
  }

  setTypVyroby(event: any) {
    this.data.typ = event.target.value
  }

  parseFormToData(form: NgForm) {
    if (form.value.pn!=''){this.data.pn=form.value.pn};
    if (form.value.name!=''){this.data.name=form.value.name};
  }
}
