import { Component, OnInit, Input} from '@angular/core';
import { CommService } from "../../comm.service";
import {NgForm} from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-analyzaLPA',
  templateUrl: './analyzaLPA.component.html',
  styleUrls: ['./../../app.component.css']
})
export class AnalyzaLPAComponent implements OnInit {
  constructor(private comm: CommService, private router:Router) { }
  nadpis1="Analýza LPA Auditů";
  komentar="";
  data_typaudit=[];

  poznamka;
  myWindow;
  analyzaid={"id":null, "od":"", "do":""};
  otazky=[];
  data=[{"datum":"", "osoba":"", "pn":"", "operator":"", "typ":"", "komentar":"", "ANO":null, "Cast":null, "NE":null, "ziskbod":null, "Celk":null, "status":""}];
  vybranopole=[];
  index=[];
  babicka={"pn":"", "total":"", "totalNOK":""};
  zavadyView=[{"name":"", "mnozstvi":"",}];
  today=new Date();
  lastmonth=new Date(new Date().setDate(new Date().getDate()-30));
  dayafter=new DatePipe('en-US').transform(this.today, 'yyyy-12-31 23:59:59.993');
  dayback=new DatePipe('en-US').transform(this.lastmonth, 'yyyy-01-01');
  datum={"typ":"", "od":"", "do":"", "doSQL":""};

  ngOnInit() {
    console.log(this.dayafter);
    console.log(this.dayback);
    this.datum.od=this.dayback;
    this.datum.do=this.dayafter;
    this.datum.doSQL=this.dayafter;
    this.datum.typ="1";
    console.log(this.datum.doSQL);
    this.getauditlpa();
    console.log("2");
    console.log(this.data);
    // setInterval(() => {
    //   this.getauditlpa();
    // }, 10000);
    this.gettypauditu();
  }

  onSubmit(form:NgForm){
    if (form.value.od!=''){this.datum.od=form.value.od};
    if (form.value.do!=''){this.datum.do=form.value.do};
    console.log(this.datum.od);
    this.datum.doSQL=this.datum.do+" 23:59:59.993";
    console.log(this.datum.doSQL);
    this.comm.getAuditLpa(this.datum).subscribe(
      //(response) => console.log(response),
      (response) => {
        this.data=response.json();
        console.log(this.data);
      },
      (error) => console.log(error));
      return this.data;
  }

  myFunctionSearch(tablename) {
    var input, filter, table, tr, td, i;
    input = document.getElementById("myInput"+tablename);
    filter = input.value.toUpperCase();
    table = document.getElementById("myTable1");
    tr = table.getElementsByTagName("tr");
    for (i = 1; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[tablename];
      if (td) {
        if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  getauditlpa(){
    this.comm.getAuditLpa(this.datum).subscribe(
      //(response) => console.log(response),
      (response) => {
        this.data=response.json();
        this.changeColor();
      },
      (error) => console.log(error));
      return this.data;
  }

  gettypauditu(){
    this.comm.typyAuditu().index().subscribe(
      (response) => { this.data_typaudit=response.json(); console.log(this.data_typaudit)},
      (error) => console.log(error));
      return this.data_typaudit;
  }

  setTyp(id){
    this.datum.typ=id.num;
    this.getauditlpa();
  }

  clickNaPolozku(polozka){
   this.analyzaid.id=polozka.auditId;
   this.poznamka=polozka.komentar;
   console.log(this.poznamka);
   this.comm.getAnalyzaAuditId(this.analyzaid).subscribe(
    (response) => {
      this.otazky=response.json();
      console.log(this.otazky);
    },
    (error) => console.log(error));
  }

  getanalyzazavadpn(){
    this.comm.getAnalyzaAuditId(this.analyzaid).subscribe(
      (response) => {
        this.otazky=response.json();
        this.comm.otazkyView=this.otazky;
      },
      (error) => console.log(error));
  }

  changeColor(){
    var i;
    for (i = 0; i < this.data.length; i++) {
    if (
      (((this.data[i].ANO * 2) + (this.data[i].Cast *1)) / (this.data[i].Celk*2))
      >= 0.85){this.data[i].status="good green"}
    else if(
      (((this.data[i].ANO * 2) + (this.data[i].Cast *1)) / (this.data[i].Celk*2))
      >=0.65){this.data[i].status="warning yellow"}
    else if(
      (((this.data[i].ANO * 2) + (this.data[i].Cast *1)) / (this.data[i].Celk*2))
    <0.65){this.data[i].status="danger red"}
  }
  console.log("Pozor:");
  console.log(this.data);
}


download(){
  var csvData = this.ConvertToCSV(this.data);
  var a = document.createElement("a");
  a.setAttribute('style', 'display:none;');
  document.body.appendChild(a);
  var blob = new Blob([csvData], { type: 'text/csv' });
  var url= window.URL.createObjectURL(blob);
  a.href = url;
  a.download = 'Data.csv';
  a.click();
}

ConvertToCSV(objArray) {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';
  var row = "";

  for (var index in objArray[0]) {
      //Now convert each value to string and comma-separated
      row += index + ';';
  }
  row = row.slice(0, -1);
  //append Label row with line break
  str += row + '\r\n';

  for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
          if (line != '') line += ';'

          line += array[i][index];
      }
      str += line + '\r\n';
  }
  return str;
}


}
