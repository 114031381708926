import { Component, OnInit } from '@angular/core'
import BaseListComponent from 'src/app/class/BaseListComponent'

@Component({
  selector: 'app-komponenty',
  templateUrl: './list.component.html',
  styleUrls: ['../../app.component.css']
})
export default class ManufacturingComponent extends BaseListComponent implements OnInit {
  activeItem: any
  data: any
  zavady=[]

  getCommEntity() {
    return this.comm.manufacturing()
  }

  getHeading() {
    return 'Aktuální výroba'
  }

  changeColor(){
    var i;
    for (i = 0; i < this.data.length; i++) {
      if (this.data[i].pocetZavad <= this.data[i].stoplineSmena*0.75){this.data[i].status="good green"}
      else if(this.data[i].pocetZavad > this.data[i].stoplineSmena*0.75 && this.data[i].pocetZavad<=this.data[i].stoplineSmena){this.data[i].status="warning yellow"}
      else if(this.data[i].pocetZavad > this.data[i].stoplineSmena){this.data[i].status="danger red"}
    }
  }

  getData() {
    this.changeColor()
    return super.getData()
  }

  onItemClick(item){
    this.activeItem = item;
    console.log(item);
    this.comm.getPocetZavad(item).subscribe(
      (response) => {
        const responseData = response.json()
        if (!responseData.error) {
          this.zavady = responseData;
          console.log(this.zavady)
          this.changeColor()
        }
      },
      (error) => {
        const message = (error.json() || {}).message ? error.json().message : 'Chyba při načítání dat'
        this.notifier.notify('error', message)
        console.error({error})
      }
    );
  }

}
