import { Component, OnInit } from '@angular/core'
import BaseListComponent from 'src/app/class/BaseListComponent'

@Component({
  selector: 'app-uzivatele',
  templateUrl: './uzivatele.component.html',
  styleUrls: ['../../app.component.css']
})
export class UzivateleComponent extends BaseListComponent implements OnInit {
  uzivatelskePozice = []

  getCommEntity() {
    return this.comm.users()
  }

  getHeading() {
    return 'Uživatelé'
  }

  loadData() {
    super.loadData()
    // load extra data
    this.comm.uzivatelskePozice().index().subscribe((res) => {this.uzivatelskePozice=res.json()},(err) => console.log(err));
  }

  getFilters() {
    return this.uzivatelskePozice.map(type => (
      {label: type.name, func: (item: any) => item.pozice === type.id, value: type.id}
    ))
  }

  getFilteredPosition() {
    const activeFilter = this.getActiveFilter()
    return activeFilter ? activeFilter.value : null // default position
  }

}
