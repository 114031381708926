import { Component, OnInit, NgZone } from '@angular/core'
import BaseListComponent from 'src/app/class/BaseListComponent'
import { CommService } from 'src/app/comm.service'
import { Router, ActivatedRoute } from '@angular/router'
import { NotifierService } from 'angular-notifier'
import { NgxSmartModalService } from 'ngx-smart-modal'
import { NgForm } from '@angular/forms'
import { DatePipe } from '@angular/common'
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"

am4core.useTheme(am4themes_animated)

@Component({
  selector: 'app-karta-a',
  templateUrl: './list.component.html',
  styleUrls: ['../../app.component.css']
})
export default class ListComponent extends BaseListComponent implements OnInit {
  data: any
  activeItem: any
  typyVyrobku = []
  datum = {from: null, to: null}
  datapn = []
  chartData = [{"timestamp": "", "measure_data":null}]
  protected chart: am4charts.XYChart;

  constructor(
    protected comm: CommService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected ngxSmartModalService: NgxSmartModalService,
    protected notifier: NotifierService,
    protected zone: NgZone
  ) {
    super(comm, router, route, ngxSmartModalService, notifier)
  }

  ngOnInit() {
    const lastmonth = new Date(new Date().setDate(new Date().getDate()-30))
    this.datum = {
      from: new DatePipe('en-US').transform(lastmonth, 'yyyy-MM-dd'),
      to: new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd')
    }
    super.ngOnInit()
  }

  getCommEntity() {
    return this.comm.cardA()
  }

  loadData() {
    this.entityComm.index(this.datum).subscribe(
      (response) => {
        const responseData = response.json()
        if (!responseData.error) {
          this.data = responseData;
          console.log(this.data);
        }
      },
      (error) => {
        const message = (error.json() || {}).message ? error.json().message : 'Chyba při načítání dat'
        this.notifier.notify('error', message)
        console.error({error})
      }
    );
    // load extra data
    this.comm.typyVyrobku().index().subscribe((res) => {this.typyVyrobku=res.json()},(err) => console.log(err));
  }

  changeColor(){
    for (let i = 0; i < this.data.length; i++) {
      if(Number(this.data[i].measure_data) > Number(this.data[i].kartaA_refValue)+Number(this.data[i].kartaA_refValueplus) || Number(this.data[i].measure_data) < Number(this.data[i].kartaA_refValue)-Number(this.data[i].kartaA_refValueminus)) {
        this.data[i].status="danger red"
      } else {
        this.data[i].status="good green"
      }
    }
  }

  getData() {
    this.changeColor()
    return super.getData()
  }

  getFilters() {
    return this.typyVyrobku.map(type => (
      {label: type.name, func: (item: any) => item.typVyrobku === type.id, value: type.id}
    ))
  }

  getFilteredType() {
    const activeFilter = this.getActiveFilter()
    return activeFilter ? activeFilter.value : 1 // default typVyrobku
  }

  onSubmit(form:NgForm){
    if (form.value.from!='') {this.datum.from = form.value.from}
    if (form.value.to!='') {this.datum.to = form.value.to}
    this.refresh()
  }

  getHeading() {
    return 'Kontrolní karta A'
  }

  loadItemData(item) {
    this.comm.getKartaA_pn(item).subscribe(
      (response) => {
        this.datapn = response.json();
        console.log(this.datapn);
        this.dateformat();
      },
      (error) => console.log(error)
    )
  }

  dateformat(){
    for (let j = 0; j < this.datapn.length; j++) {
      this.datapn[j].timestamp = new Date(this.datapn[j].timestamp)
    }
    this.amChart()
  }

  amChart() {
    this.zone.runOutsideAngular(() => {
      // Create chart instance
      let chart = am4core.create("chartdiv", am4charts.XYChart);

      // Add data
      chart.data = this.datapn;
      // Create axes
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 60;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      // Create series
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "measure_data";
      series.dataFields.dateX = "timestamp";
      series.tooltipText = "{valueY}mm";

      series.tooltip.pointerOrientation = "vertical";

      var bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.strokeWidth = 2;
      bullet.fill = am4core.color("#000");
      bullet.stroke = am4core.color("#fff");
      bullet.setStateOnChildren = true;
      bullet.propertyFields.fillOpacity = "opacity";
      bullet.propertyFields.strokeOpacity = "opacity";

      var plusrange = valueAxis.createSeriesRange(series);
      plusrange.value = Number(this.activeItem.kartaA_refValue) + Number(this.activeItem.kartaA_refValueplus);
      plusrange.endValue = 1000;
      plusrange.contents.stroke = am4core.color("#f02020");
      plusrange.contents.fill = plusrange.contents.stroke;


      var minusrange = valueAxis.createSeriesRange(series);
      minusrange.value = Number(this.activeItem.kartaA_refValue) - Number(this.activeItem.kartaA_refValueminus);
      minusrange.endValue = -1000;
      minusrange.contents.stroke = am4core.color("#FF0000");
      minusrange.contents.fill = minusrange.contents.stroke;


      // Create value axis range
      var range = valueAxis.axisRanges.create();
      range.value = Number(this.activeItem.kartaA_refValue)+Number(this.activeItem.kartaA_refValueplus);
      range.grid.stroke = am4core.color("#FF0000");
      range.grid.strokeWidth = 1;
      range.grid.strokeOpacity = 1;
      range.label.inside = true;
      range.label.text = "MAX";
      range.label.fill = range.grid.stroke;
      //range.label.align = "right";
      range.label.verticalCenter = "bottom";

      // Create value axis range
      var range = valueAxis.axisRanges.create();
      range.value = Number(this.activeItem.kartaA_refValue)-Number(this.activeItem.kartaA_refValueminus);
      range.grid.stroke = am4core.color("#FF0000");
      range.grid.strokeWidth = 1;
      range.grid.strokeOpacity = 1;
      range.label.inside = true;
      range.label.text = "MIN";
      range.label.fill = range.grid.stroke;
      //range.label.align = "right";
      range.label.verticalCenter = "bottom";

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = dateAxis;

      // Create a horizontal scrollbar with previe and place it underneath the date axis
      chart.scrollbarX = new am4charts.XYChartScrollbar();
      chart.scrollbarX.parent = chart.bottomAxesContainer;

      chart.events.on("ready", function () {
        dateAxis.zoom({start:0, end:1});
      });

      chart.exporting.menu = new am4core.ExportMenu();
      this.chart = chart;
    });
  }
}
